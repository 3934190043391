//*//
//      Error boundary css start
//==========================  **/

/* Base styles */

    .error-boundary {
        background: #fff;
        height: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &__text-holder {
            position: absolute;
            // border: 5px solid;
            z-index: 1;
            padding: 20px;
            font-weight: 500;
            top: 15%;
        }

        &__image-holder {
            padding: 30px;

            svg {
                max-width: 500px;
                width: 100%;
                opacity: 0.6;
            }

        }
    }
